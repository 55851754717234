
export const DEFAULT_MONTH_DROPDOWN_ITEM = { label: 'Month*', value: undefined };

export const MONTHS_DROPDOWN_ITEMS = [
  DEFAULT_MONTH_DROPDOWN_ITEM,
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const getCCExpirationYears = (monthId: number | null = 12): number[] => {
  const currentYear: number = new Date().getFullYear();
  const currentMonth: number = new Date().getMonth() + 1; // offset for getMonth returning 0 for January
  
  const years: number[] = [];

  // If the provided monthId is less than the current month, start from the next year
  const startYear = monthId && monthId < currentMonth ? currentYear + 1 : currentYear;

  for (let i = 0; i < 10; i++) {
    years.push(startYear + i);
  }

  return years;
}

export const accountTypes = [
  { label: 'Account Type*', value: null},
  { label: 'Checking', value: 'checking' },
  { label: 'Savings', value: 'savings' },
]