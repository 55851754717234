export const TEXT_CONSTANTS = {
  quoteOne:
    '“Prison Fellowship’s Angel Tree is a life-changing experience— for everyone involved.”',
  quoteOneWriter: 'CHUCK COLSON',
  ZERO_KIDS_ORG:
    'Thank you! All the children have been sponsored by this Organization.',
  PUBLIC_KIDS_BELOW_TH: 'Thank you! All the children have been sponsored.',
  CONTENTION_SPONSORED:
    'Another Sponsor already donated for these children.  We’ve substituted similar children for your donation from the public list.',
  ORG_ERROR_MSG_BE:
    "We couldn't find the Organization. Please contact support.",
  ORG_SEARCH_LINK_TEXT: 'Search by age and gender',
  PUB_SEARCH_LINK_TEXT: 'Search by age, gender, and location',
  PAYMENT_ERROR_KEY: 'sponsor_processing_failed',
  PAYMENT_ERROR_MSG:
    "Payment went through successfully but due to some error selected kids have not been sponsored. You don't need to make another donation for same kid(s); we will do that manually.",
  NO_RECORD_MSG: "Praise the Lord! All Children of your Organization have been Sponsored."
  };

export const ALERT_MESSAGE = {
  INVALID_RESPONSE: 'Invalid response from server',
  BASKET_FULL:
    "We couldn't add the gift since you've reached the basket limit of ",
  DUPLICATE: 'Gift is already in basket.',
  NO_SEARCH_RESULTS:
    "Your search didn't match any children so we're showing results from a default search.",
  DEFAULT_NAT_ORG:
    "We couldn't find the specified Org. Showing results for national search.",
  SESSION_EXPIRE:
    'Baskets are emptied after 30 minutes of inactivity. Please begin again with a new search.',
};

export const VALIDATE_MESSAGES = {
  REQUIRED: 'Required'
};

export const QUOTES = [
  {
    quote: `"My wife and I have been fostering kids for almost 15 years, and your wonderful organization has served many, many of the children temporarily in our home. We’re so grateful for your ministry. God bless you in your efforts to serve these kids!"`,
    author: `-Foster Dad`,
  },
  {
    quote: `"It means so much for the kids to see something from their absent parent under the Christmas tree. It gives them hope. Thank God for Angel Tree volunteers!"`,
    author: ``,
  },
  {
    quote: `"It provides a service for parents in prison to tell their children they are thinking of them. Children suffer the most when parents make mistakes. Their hearts are fragile. Their eyes light up and they are excited their parent thought of them."`,
    author: ``,
  },
  {
    quote: `"It is so humbling knowing that someone cares enough to help brighten your holiday. Watching our kids eyes light up was wonderful. Being a prison family is tough and they helped brighten it for us."`,
    author: ``,
  },
  {
    quote: `"My brother and I would like to thank you for caring about us this Christmas. Even though our father was the one who contacted you, your foundation was kind enough to manifest the love has for us."`,
    author: `-Nicole`,
  },
  {
    quote: `"My sister and I are very grateful for sending us a reminder of our father's love."`,
    author: `-Christopher`,
  },
  {
    quote: `"When I read the personal message from their mom, it was as if their whole holiday was complete. They felt their mom didn’t forget them."`,
    author: `-Karen`,
  },
  {
    quote: `"Angel Tree is like love spreading over the whole world."`,
    author: `-Elijah`,
  },
  {
    quote: `"It’s always been great that my dad gives us [Angel Tree] gifts. … It’s great that I know he’s still there for me. I love Angel Tree."`,
    author: `-Joseph`,
  },
  {
    quote: `"Angel Tree always comes at the perfect time. I just want my baby to have a good holiday and feel loved. Angel Tree helps that happen."`,
    author: `-Alyssa`,
  },
  {
    quote: `"Every [Angel Tree family] is very appreciative. Caregivers know what it's all about. They just continually thank us and are so appreciative. They say, 'I hope God blesses you all for what you're doing. It's so important.'"`,
    author: '-Ruby',
  },
];
