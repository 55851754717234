import React, { useState } from 'react';
import { Card, PfCardButton } from '../common';
import { getChildAge, useWindowDimensions } from '../../utils';
import { IChild } from '../../types';
import { EGender, EGenderMap } from '../../redux/refdata.redux';
import { Modal, ModalBody } from 'reactstrap';
import {
  IconCheckedCircle,
  IconUncheckedCircle,
  layoutBreakpoints,
} from '../../constants';
import GiftIcon from '../gift-icon/gift-icon';

export interface IChildCardProps {
  id: number;
  child: IChild;
  chooseChild: (event: any, id: number) => void;
  checked?: boolean;
  parentMessage: string;
  genders?: EGender[];
  detailedMessage?: string;
}

const ChildCard: React.FC<IChildCardProps> = ({
  id,
  child: { firstName, age, gender = '', city, state } = {},
  chooseChild,
  checked = false,
  parentMessage = '',
  detailedMessage = '',
}) => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  const { width } = useWindowDimensions();
  const isMobileLayout = width < layoutBreakpoints.L;

  return (
    <Card className="p-0 child-container" key={id}>
      <div
        onClick={(event) => chooseChild(event, id)}
        className={`flex-column child-card ${checked ? 'checked-card' : ''}`}
      >
        <GiftIcon id={id} />
        {checked && <IconCheckedCircle className="selection-icon" />}
        {isMobileLayout && !checked && (
          <IconUncheckedCircle className="selection-icon" />
        )}
        <div className="child-info">
          <p className="child-name">
            <b>{`${firstName}`}</b>
          </p>
          <p className="child-details">
            {`${EGenderMap[gender] || 'Not Known'} | ${getChildAge(age)} | ${
              !!city ? `${city}, ` : ''
            }${state}`}
          </p>
        </div>

        <div
          className="message-container"
          onClick={(event) => {
            event.stopPropagation();
            detailedMessage && setShowMessageModal(true);
          }}
        >
          <span className="parent-message-title parent-message">
            Message:&nbsp;
          </span>
          &quot;{parentMessage}&quot;
        </div>

        {!isMobileLayout && <PfCardButton checked={checked} />}
      </div>
      <Modal
        isOpen={showMessageModal}
        toggle={() => setShowMessageModal(false)}
        className="cvv-modal"
        backdrop
      >
        <ModalBody>{detailedMessage}</ModalBody>
      </Modal>
    </Card>
  );
};

export default ChildCard;
