
import { VALIDATE_MESSAGES } from '../constants';

export const required = (value: string) =>
  value ? undefined : VALIDATE_MESSAGES.REQUIRED;

export const checkBasketLimit = (basketCount: number, limit: number) => {
  if (limit === 0) {
    return false;
  }
  return basketCount > limit;
};
