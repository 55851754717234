import { Gift1, Gift2, Gift3 } from "../../constants";


interface IProps {
  id: number | string
}

const GiftIcon: React.FunctionComponent<IProps> = ({ id }) => {
  try {
    let number = id;
    if (typeof id === 'string') {
      number = parseInt(id || '0', 10);
    }
    // @ts-ignore
    const index = number % 3;

    if (index === 0) {
      return <Gift1 className="child-header-image" />;
    } else if (index === 2) {
      return <Gift2 className="child-header-image" />;
    } else {
      return <Gift3 className="child-header-image" />;
    }
  } catch (e) {
    return <Gift1 className="child-header-image" />;
  }
};

export default GiftIcon